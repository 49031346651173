import React from 'react'
import { Icon } from '@blueprintjs/core'
import _ from 'lodash'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import getYoutubeUrl from 'canonical-youtube-url'

import { zapStyles } from 'v2/components/widgets/definitions'
import LockedOverlay from '../../../components/lockedOverlay'

// Hardcode this for now, maybe allow options in the future
const boxShadow = (props) => {
    return props.boxShadow ? '0 4px 8px #00000029' : 'none'
}

export const EmptyRenderer = styled.div`
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
`

const StyledMargins = styled.div`
    margin-left: ${(props) => props.marginLeft}px;
    margin-right: ${(props) => props.marginRight}px;
    margin-top: ${(props) => props.marginTop}px;
    margin-bottom: ${(props) => props.marginBottom}px;
`

const StyledBox = styled(StyledMargins)`
    padding-left: ${(props) => props.paddingLeft}px;
    padding-right: ${(props) => props.paddingRight}px;
    padding-top: ${(props) => props.paddingTop}px;
    padding-bottom: ${(props) => props.paddingBottom}px;
`

export const StyledZapImage = styled(StyledBox)`
    background-color: ${(props) => props.backgroundColor};

    img {
        width: 100%;
        height: 100%;
    }
`

export const StyledZapImageFull = styled(StyledBox)`
    width: ${(props) => props.width * 375}px;
    height: ${(props) => props.height * 812}px;
    background-color: ${(props) => props.backgroundColor};

    img {
        width: ${(props) => (props.width * 375) - props.paddingLeft - props.paddingRight}px;
        height: ${(props) => (props.height * 812) - props.paddingTop - props.paddingBottom}px;
        object-fit: contain;
    }
`

export const StyledZapImageBanner = styled(StyledBox)`
    width: ${(props) => props.width * 375}px;
    height: ${(props) => props.height * 812}px;
    background-color: ${(props) => props.backgroundColor};

    img {
        width: ${(props) => (props.width * 375) - props.paddingLeft - props.paddingRight}px;
        height: ${(props) => (props.height * 812) - props.paddingTop - props.paddingBottom}px;
        object-fit: cover;
    }
`

export const StyledZapButton = styled(StyledBox)`
    display: inline-block;
    user-select: none;

    background-color: ${(props) => props.backgroundColor};

    border-radius: ${(props) => props.borderRadius}px;
    border-width: ${(props) => props.borderSize}px;
    border-color: ${(props) => props.borderColor};
    border-style: solid;

    border-left-width: ${(props) => props.borderLeftSize}px;
    border-left-color: ${(props) => props.borderLeftColor};

    border-right-width: ${(props) => props.borderRightSize}px;
    border-right-color: ${(props) => props.borderRightColor};

    border-bottom-width: ${(props) => props.borderBottomSize}px;
    border-bottom-color: ${(props) => props.borderBottomColor};

    border-top-width: ${(props) => props.borderTopSize}px;
    border-top-color: ${(props) => props.borderTopColor};
`

const lineClamp = `
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: `

export const StyledZapText = styled(StyledBox)`
    font-family: ${(props) => props.fontFamily};
    font-weight: ${(props) => props.fontWeight};
    font-size: ${(props) => props.fontSize}px;

    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};

    text-align: ${(props) => props.textAlign};

    ${(props) => props.maxLines ? (lineClamp + props.maxLines) : 'display: block'};
`

export const StyledZapRichText = styled(StyledZapText)`
    font-family: ${(props) => props.fontFamily};
    font-weight: ${(props) => props.fontWeight};
    font-size: ${(props) => props.fontSize}px;

    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.textColor};

    text-align: ${(props) => props.textAlign};

    p {
        margin-bottom: 0;
    }
`

export const StyledZapImageTitleTopButton = styled(StyledMargins)`
    background-color: ${(props) => props.backgroundColor};
    border-width: ${(props) => props.borderSize}px;
    border-color: ${(props) => props.borderColor};
    border-style: solid;

    min-width: 150px;
    min-height: 75px;

    position: relative;

    .bg {
    }

    .text {
        padding-left: ${(props) => props.paddingLeft}px;
        padding-right: ${(props) => props.paddingRight}px;
        padding-top: ${(props) => props.paddingTop}px;
        padding-bottom: ${(props) => props.paddingBottom}px;
        background-color: ${(props) => props.borderColor};
        > div {
            display: inline-block;
        }
    }
`

export const StyledZapImageTitleBottomButton = styled(StyledZapImageTitleTopButton)`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

export const StyledZapIconTitleBottomButton = styled(StyledBox)`
    background-color: ${(props) => props.backgroundColor};
    border-width: ${(props) => props.borderSize}px;
    border-color: ${(props) => props.borderColor};
    border-style: solid;

    min-width: 75px;
    min-height: 75px;

    position: relative;

    .bg {
    }

    .text {
        padding-left: ${(props) => props.paddingLeft}px;
        padding-right: ${(props) => props.paddingRight}px;
        padding-top: ${(props) => props.paddingTop}px;
        padding-bottom: ${(props) => props.paddingBottom}px;
        background-color: ${(props) => props.borderColor};
        > div {
            display: inline-block;
        }
    }
`

export const StyledZapListItem = styled(StyledBox)``
export const StyledZapListItemImage = styled(StyledBox)`
    display: flex;
    align-items: center;
    img {
        max-height: 38px;
        max-width: 38px;
    }
`

export const StyledZapCard = styled(StyledBox)`
    width: ${(props) => props.width * 375}px;
    height: ${(props) => props.height * 812}px;
    background-color: ${(props) => props.backgroundColor};

    border-radius: ${(props) => props.borderRadius}px;
    border-width: ${(props) => props.borderSize}px;
    border-color: ${(props) => props.borderColor};
    border-style: solid;
    box-shadow: ${(props) => boxShadow(props)};
`
export const StyledZapCardLeftImage = styled(StyledZapCard)``
export const StyledZapCardNoImage = styled(StyledZapCard)``
export const StyledZapCardYoutube = styled(StyledZapCard)``
export const StyledZapCardEvent = styled(StyledZapCard)`
    .event-card-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
`
export const StyledZapCardPoll = styled(StyledBox)`
    width: 375px;
    position: relative;
    background-color: #f2f2f2;
    padding: 10px;
    font-weight: bold;

    .bp3-icon {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .choice {
        margin-top: 10px;
        border: 2px solid #00598c;
        border-radius: 10px;
        background-color: white;
        padding: 3px 5px;
        color: #003b5c;
    }
`

export const RenderZapElementBody = ({ elem, renderer }) => {
    switch (renderer) {
        case 'StyledZapButton':
            return elem.body.map((e, i) => {
                const data = e.data || e.defaultData

                if (!zapStyles[e.style]) {
                    return <div key={i}>nogo</div>
                }
                const styles = {
                    ...zapStyles[e.style].defaultData,
                    ...data,
                }
                const value = _.get(e, 'data.value', e.defaultValue)
                return (
                    <StyledZapText key={i} {...styles}>
                        {value}
                    </StyledZapText>
                )
            })
        case 'StyledZapListItemImage': {
            const [text, bg] = elem.body

            const textStyles = {
                ...zapStyles[text.style].defaultData,
                ...text.defaultData,
                ...text.data,
            }
            const value = _.get(text, 'data.value', text.defaultValue)

            const imageStyles = {
                ...zapStyles[bg.style].defaultData,
                ...bg.data,
            }

            delete imageStyles.image

            const base64 = _.get(bg.data, 'image.base64')
            const src = _.get(bg.data, 'image.url', base64)
            const renderImage = src ? (
                <StyledZapImage {...imageStyles}>
                    <img src={src} alt="" />
                </StyledZapImage>
            ) : null

            return (
                <React.Fragment>
                    {renderImage}
                    <StyledZapText {...textStyles}>{value}</StyledZapText>
                </React.Fragment>
            )
        }
        case 'StyledZapListItem': {
            const [text1, text2] = elem.body
            const text1Styles = {
                ...zapStyles[text1.style].defaultData,
                ...text1.defaultData,
                ...text1.data,
            }
            const value1 = _.get(text1, 'data.value', text1.defaultValue)

            if (!text2) {
                return <StyledZapText {...text1Styles}>{value1}</StyledZapText>
            }

            const text2Styles = {
                ...zapStyles[text1.style].defaultData,
                ...text2.defaultData,
                ...text2.data,
            }
            const value2 = _.get(text2, 'data.value', text2.defaultValue)

            return (
                <div>
                    <StyledZapText {...text1Styles}>{value1}</StyledZapText>
                    <StyledZapText {...text2Styles}>{value2}</StyledZapText>
                </div>
            )
        }
        case 'StyledZapIconTitleBottomButton':
        case 'StyledZapImageTitleTopButton':
        case 'StyledZapImageTitleBottomButton': {
            const [text, bg] = elem.body

            const textStyles = {
                ...zapStyles[text.style].defaultData,
                ...text.defaultData,
                ...text.data,
            }
            const value = _.get(text, 'data.value', text.defaultValue)

            let renderImage = null
            if (bg) {
                const imageStyles = {
                    ...zapStyles[bg.style].defaultData,
                    ...bg.data,
                }

                delete imageStyles.image

                const base64 = _.get(bg.data, 'image.base64')
                const src = _.get(bg.data, 'image.url', base64)
                if (src) {
                    renderImage = (
                        <StyledZapImage {...imageStyles}>
                            <img src={src} alt="" />
                        </StyledZapImage>
                    )
                }
            }

            if (renderer === 'StyledZapImageTitleTopButton') {
                return (
                    <React.Fragment>
                        <div
                            className="text"
                            style={{ textAlign: textStyles.textAlign }}
                        >
                            <StyledZapText {...textStyles}>
                                {value}
                            </StyledZapText>
                        </div>
                        {renderImage}
                    </React.Fragment>
                )
            }

            return (
                <React.Fragment>
                    {renderImage}
                    <div
                        className="text"
                        style={{ textAlign: textStyles.textAlign }}
                    >
                        <StyledZapText {...textStyles}>{value}</StyledZapText>
                    </div>
                </React.Fragment>
            )
        }
        case 'StyledZapText': {
            const [text] = elem.body
            let value = elem.defaultValue
            if (text && text.data) {
                value = _.get(text, 'data.value', elem.defaultValue)
                return <StyledZapText {...text.data}>{value}</StyledZapText>
            }
            return <div>{value}</div>
        }
        case 'StyledZapRichText': {
            const [text] = elem.body
            let value = elem.defaultValue
            if (text && text.data) {
                value = _.get(text, 'data.value', elem.defaultValue)
                return (
                    <StyledZapRichText {...text.data}>
                        <ReactMarkdown skipHtml children={value} />
                    </StyledZapRichText>
                )
            }
            return <ReactMarkdown skipHtml children={value} />
        }
        case 'StyledZapImageFull':
        case 'StyledZapImageBanner':
        case 'StyledZapImage': {
            const [image] = elem.body

            let src = _.get(elem, 'defaultImage.base64')
            let style = {}

            if (image && image.data) {
                const base64 = _.get(image, 'data.image.base64')
                src = _.get(image, 'data.image.url', base64)
                style = image.data
            }

            if (renderer === 'StyledZapImageFull') {
                return (
                    <StyledZapImageFull {...style}>
                        <img src={src} alt="" />
                    </StyledZapImageFull>
                )
            }

            else if (renderer === 'StyledZapImageBanner') {
                return (
                    <StyledZapImageBanner {...style}>
                        <img src={src} alt="" />
                    </StyledZapImageBanner>
                )
            }

            else {
                return (
                    <StyledZapImage {...style}>
                        <img src={src} alt="" />
                    </StyledZapImage>
                )
            }
        }
        case 'StyledZapCard':
        case 'StyledZapCardLeftImage':
        case 'StyledZapCardNoImage': {
            let image, textPrimary, textSecondary, renderImage

            if (['StyledZapCardNoImage', 'StyledZapCardPoll'].includes(renderer)) {
                [textPrimary, textSecondary] = elem.body
            }
            else {
                [image, textPrimary, textSecondary] = elem.body
            }

            const textStylesPrimary = {
                ...zapStyles[textPrimary.style].defaultData,
                ...textPrimary.defaultData,
                ...textPrimary.data,
            }

            const textStylesSecondary = {
                ...zapStyles[textSecondary.style].defaultData,
                ...textSecondary.defaultData,
                ...textSecondary.data,
            }

            const valuePrimary = _.get(textPrimary, 'data.value', textPrimary.defaultValue)
            const valueSecondary = _.get(textSecondary, 'data.value', textSecondary.defaultValue)

            const defaultSrc = _.get(elem, 'defaultImage.base64')

            if (renderer !== 'StyledZapCardNoImage') {
                renderImage = (
                    <StyledZapImage style={{ width: 40, height: 40 }}>
                        <img src={defaultSrc} alt="" />
                    </StyledZapImage>
                )
            }

            if (image) {
                const imageStyles = {
                    ...zapStyles[image.style].defaultData,
                    ...image.data,
                }

                delete imageStyles.image

                const base64 = _.get(image.data, 'image.base64')
                const src = _.get(image.data, 'image.url', base64)
                if (src) {
                    renderImage = (
                        <StyledZapImage {...imageStyles}>
                            <img src={src} alt="" />
                        </StyledZapImage>
                    )
                }
            }

            const textItems = (
                <React.Fragment>
                    <div
                        className="text"
                        style={{ textAlign: textStylesPrimary.textAlign }}
                    >
                        <StyledZapText {...textStylesPrimary}>
                            {valuePrimary}
                        </StyledZapText>
                    </div>
                    <div
                        className="text"
                        style={{ textAlign: textStylesSecondary.textAlign }}
                    >
                        <StyledZapText {...textStylesSecondary}>
                            {valueSecondary}
                        </StyledZapText>
                    </div>
                </React.Fragment>
            )

            if (renderer === 'StyledZapCardLeftImage') {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ flex: 1 }}>
                            {renderImage}
                        </div>
                        <div style={{ flex: 2 }}>
                            {textItems}
                        </div>
                    </div>
                )
            }

            return (
                <React.Fragment>
                    {renderImage}
                    {textItems}
                </React.Fragment>
            )
        }
        case 'StyledZapCardEvent': {
            const textStyles = elem.body.map(item => ({
                ...zapStyles[item.style].defaultData,
                ...item.defaultData,
                ...item.data,
            }))

            const textValues = elem.body.map(item => _.get(
                item, 'data.value', item.defaultValue
            ))

            const rightTexts = elem.body.slice(1).map((item, i) => (
                <div
                    key={i}
                    className="text"
                    style={{ textAlign: textStyles[i + 1].textAlign }}
                >
                    <StyledZapText {...textStyles[i + 1]}>
                        {textValues[i + 1]}
                    </StyledZapText>
                </div>
            ))

            return (
                <div className="event-card-wrapper">
                    <div
                        className="text"
                        style={{ textAlign: textStyles[0].textAlign }}
                    >
                        <StyledZapText {...textStyles[0]}>
                            {textValues[0]}
                        </StyledZapText>
                    </div>
                    <div>
                        {rightTexts}
                    </div>
                </div>
            )
        }
        case 'StyledZapCardPoll': {
            const question = _.get(elem, 'data.poll.question')
            const choices = _.get(elem, 'data.poll.choices')

            if (!question || !choices) {
                return (
                    <div>
                        <Icon icon="cross" color="#00598c" />
                        Poll Question
                        <div className="choice">
                            Choice 1
                        </div>
                        <div className="choice">
                            Choice 2
                        </div>
                        <div className="choice">
                            Choice 3
                        </div>
                    </div>
                )
            }

            return (
                <div>
                    <Icon icon="cross" color="#00598c" />
                    {question}
                    {choices.map(choice => (
                        <div className="choice">
                            {choice}
                        </div>
                    ))}
                </div>
            )
        }
        case 'StyledZapCardYoutube': {
            try {
                const url = getYoutubeUrl(_.get(elem, 'data.videoUrl'))

                return (
                    <iframe
                        src={url.replace('www.youtube.com/watch?v=', 'www.youtube.com/embed/')}
                        title={url}
                    />
                )
            }

            catch (err) {
                return (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <Icon icon="video" size={100} color="red" />
                    </div>
                )
            }
        }
        default:
            return (
                <div>
                    This widget uses custom code and cannot be modified.
                    <LockedOverlay />
                </div>
            )
    }
}
