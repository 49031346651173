import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import produce from 'immer'
import classname from 'classnames'
import { useRecoilValue, useRecoilState } from 'recoil'
import { documentState, panelStackState } from 'v2/components/manager/document'
import DocumentHeader from 'v2/components/preview/header'
import DocumentLoader from 'v2/components/preview/loader'
import WebLoader from 'v2/components/preview/web'
import {
    StyledZapButton,
    StyledZapText,
    StyledZapRichText,
    StyledZapImage,
    StyledZapImageFull,
    StyledZapImageBanner,
    StyledZapImageTitleTopButton,
    StyledZapImageTitleBottomButton,
    StyledZapIconTitleBottomButton,
    StyledZapListItem,
    StyledZapListItemImage,
    StyledZapCard,
    StyledZapCardLeftImage,
    StyledZapCardNoImage,
    StyledZapCardEvent,
    StyledZapCardPoll,
    StyledZapCardYoutube,
    EmptyRenderer,
    RenderZapElementBody,
} from 'v2/components/widgets/styles'
import { useContextTools } from 'v2/components/manager/api'
import { zapStyles } from 'v2/components/widgets/definitions'

const styleRenderers = {
    StyledZapButton,
    StyledZapText,
    StyledZapRichText,
    StyledZapImage,
    StyledZapImageFull,
    StyledZapImageBanner,
    StyledZapImageTitleTopButton,
    StyledZapImageTitleBottomButton,
    StyledZapIconTitleBottomButton,
    StyledZapListItem,
    StyledZapListItemImage,
    StyledZapCard,
    StyledZapCardLeftImage,
    StyledZapCardNoImage,
    StyledZapCardEvent,
    StyledZapCardPoll,
    StyledZapCardYoutube,
}

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 632px; /* This corresponds to offset of height size */
    overflow-y: scroll;

    .layout-divider {
        padding: 2px 10px;
        margin-bottom: 4px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        width: 100%;
    }

    .bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }
`

const StyledView = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
`

const StyledViewBackground = styled.div`
    display: block;
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
`

const StyledLayoutRow = styled.div`
    display: flex;

    &.scroll {
        flex-wrap: nowrap;
        overflow-x: auto;
    }
`

const StyledLayout = styled.div`
    flex: 1;
    display: flex;
    position: relative;

    > div {
        flex: 1;
    }

    &.un-styled {
        padding: 10px;
        background: #ff6302;
        color: #ffb78a;
        font-weight: bold;
        text-align: center;
        margin: 2px;
    }

    .target {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;

        cursor: pointer;
        border-radius: 3px;

        &:hover {
            background: rgba(102, 168, 220, 0.25);
        }

        &.selected {
            border: 2px solid rgba(102, 168, 220, 0.75);
        }
    }
`

const RenderLayoutContainer = ({ layout, dataDriven, ...props }) => {
    const [stack, setStack] = useRecoilState(panelStackState)
    const { isSelected, selectElement } = useContextTools()
    const style = zapStyles[layout.style] || {}
    let previewId = (dataDriven ? 'dd-' : 'preview-') + layout.id
    let Render = EmptyRenderer
    let overlay

    const _onLayoutSelect = () => {
        if (dataDriven) {
            const url = _.get(layout, 'layout.navigation.url.url')

            if (url) {
                window.open(url, '_blank')
            }
        }

        else {
            const editorMate = document.getElementById('editor-' + layout.id)

            if (editorMate && editorMate.scrollIntoViewIfNeeded) {
                editorMate.scrollIntoViewIfNeeded()
            }

            selectElement(layout)
        }
    }

    const _onNavigate = () => {
        if (!layout.navigation || dataDriven) {
            return
        }

        const load = _.get(layout, 'navigation.module.id')

        const newPanel = layout.navigation.url
            ? {
                  component: WebLoader,
                  props: { url: layout.navigation.url },
                  title: <DocumentHeader header={{}} />,
              }
            : {
                  component: DocumentLoader,
                  props: {
                      load,
                  },
                  title: <DocumentHeader header={{}} load={load} />,
              }
        const nextState = produce(stack, (draftState) => {
            draftState.push(newPanel)
        })
        setStack(nextState)
    }

    if (!layout.style) {
        return (
            <StyledLayout className="un-styled">
                Add a style to this element
            </StyledLayout>
        )
    }

    if (style.renderer) {
        Render = styleRenderers[style.renderer]
    }

    if (!dataDriven || layout.style !== 'zap__card_youtube') {
        overlay = (
            <div
                className={classname('target', {
                    selected: isSelected(layout),
                })}
                onClick={_onLayoutSelect}
                onDoubleClick={_onNavigate}
            />
        )
    }

    return (
        <StyledLayout id={previewId}>
            <Render {...layout.data}>
                <RenderZapElementBody elem={layout} renderer={style.renderer} />
            </Render>
            {overlay}
        </StyledLayout>
    )
}

const RenderLayoutRow = ({ row, dataDriven, ...props }) => {
    const padding = _.get(row, 'data.padding', props.padding)
    const scroll = _.get(row, 'data.scroll')

    return (
        <StyledLayoutRow
            className={scroll ? 'scroll' : null}
            style={{ padding }}
        >
            {row.body.map((layout) => (
                <RenderLayoutContainer
                    key={layout.id}
                    layout={layout}
                    dataDriven={dataDriven}
                />
            ))}
        </StyledLayoutRow>
    )
}

RenderLayoutRow.defaultProps = {
    padding: 0,
}

const RenderLayoutDivider = () => {
    return <div className="layout-divider"></div>
}

const ViewBackground = ({ image }) => {
    const base64 = _.get(image, 'base64')
    const src = _.get(image, 'url', base64)

    if (!src) {
        return null
    }

    return (
        <StyledViewBackground className="bg">
            <img src={src} alt="" />
        </StyledViewBackground>
    )
}

const View = ({ doc, onScroll }) => {
    const docFromState = useRecoilValue(documentState)
    const { backgroundColor } = _.get(docFromState, 'data', {})

    return (
        <Wrapper onScroll={onScroll} style={{ backgroundColor }}>
            <ViewBackground {...(doc || docFromState).data} />
            <StyledView>
                {(doc || docFromState).body.map((row) => {
                    if (row.type === 'LayoutDivider') {
                        return <RenderLayoutDivider key={row.id} />
                    }

                    else {
                        return (
                            <RenderLayoutRow
                                key={row.id}
                                row={row}
                                dataDriven={!!doc}
                            />
                        )
                    }
                })}
            </StyledView>
        </Wrapper>
    )
}

export default View
