import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
    Dialog,
    Classes,
    Button,
    Intent,
    FormGroup,
    InputGroup,
    Checkbox,
    Divider,
    RadioGroup,
    Radio,
} from '@blueprintjs/core'

import { useDocumentUpdateTools } from 'v2/components/manager/api'

const FeedOptions = ({ onUpdate, dataSource }) => {
    const initUrl = _.get(dataSource, 'url')
    const initSelector = _.get(dataSource, 'selector')
    const initLimitParameter = _.get(dataSource, 'limitParameter')
    const initOffsetParameter = _.get(dataSource, 'offsetParameter')
    const initOffsetItemBased = _.get(dataSource, 'offsetItemBased', true)
    const initOffsetZeroBased = _.get(dataSource, 'offsetZeroBased', true)

    let initLimit = _.get(dataSource, 'limit')
    let scrollingControls = null

    if (!Number.isInteger(initLimit) || initLimit < 0) {
        initLimit = 0
    }

    const [url, setUrl] = useState(initUrl || '')
    const [selector, setSelector] = useState(initSelector || '')
    const [limit, setLimit] = useState(initLimit)
    const [limitParameter, setLimitParameter] = useState(initLimitParameter || '')
    const [scrolling, setScrolling] = useState(initOffsetParameter !== undefined)
    const [offsetParameter, setOffsetParameter] = useState(initOffsetParameter || '')
    const [offsetItemBased, setOffsetItemBased] = useState(initOffsetItemBased)
    const [offsetZeroBased, setOffsetZeroBased] = useState(initOffsetZeroBased)

    useEffect(() => {
        setUrl(_.get(dataSource, 'url', ''))
        setSelector(_.get(dataSource, 'selector', ''))
        setLimit(_.get(dataSource, 'limit', 0))
        setLimitParameter(_.get(dataSource, 'limitParameter', ''))
        setOffsetParameter(_.get(dataSource, 'offsetParameter'))
        setOffsetItemBased(_.get(dataSource, 'offsetItemBased', true))
        setOffsetZeroBased(_.get(dataSource, 'offsetZeroBased', true))
    }, [dataSource])

    const _update = () => {
        let params = { url, selector, limit, limitParameter }

        if (scrolling) {
            params.offsetParameter = offsetParameter || ''
            params.offsetItemBased = offsetItemBased
            params.offsetZeroBased = offsetZeroBased
        }

        onUpdate(params)
    }

    if (scrolling) {
        scrollingControls = (
            <React.Fragment>
                <FormGroup
                    labelFor="offsetParameter"
                    label="Name of offset parameter in URL"
                    labelInfo="(optional)"
                    helperText="This will reduce the amount of network traffic."
                >
                    <InputGroup
                        id="offsetParameter"
                        placeholder="start"
                        defaultValue={offsetParameter}
                        onChange={(event) => setOffsetParameter(event.currentTarget.value)}
                    />
                </FormGroup>

                <RadioGroup
                    inline
                    selectedValue={offsetItemBased.toString()}
                    onChange={(event) => setOffsetItemBased(event.currentTarget.value === 'true')}
                >
                    <Radio label="Parameter counts items" value="true" />
                    <Radio label="Parameter counts pages" value="false" />
                </RadioGroup>

                <RadioGroup
                    inline
                    selectedValue={offsetZeroBased.toString()}
                    onChange={(event) => setOffsetZeroBased(event.currentTarget.value === 'true')}
                >
                    <Radio label="Starts from 0" value="true" />
                    <Radio label="Starts from 1" value="false" />
                </RadioGroup>
            </React.Fragment>
        )
    }

    return (
        <div>
            <h3>From a JSON Feed</h3>
            <p>
                Use this option if your endpoint returns a JSON array.
                A copy of this row will be produced for each item.
            </p>

            <FormGroup labelFor="url" label="URL">
                <InputGroup
                    id="url"
                    maxLength={500}
                    placeholder="http://example.com/api/endpoint"
                    value={url}
                    onChange={(event) => setUrl(event.currentTarget.value)}
                />
            </FormGroup>

            <FormGroup labelFor="selector" label="Selector">
                <InputGroup
                    id="selector"
                    maxLength={100}
                    placeholder="items"
                    value={selector}
                    onChange={(event) => setSelector(event.currentTarget.value)}
                />
            </FormGroup>

            <FormGroup
                labelFor="limit"
                label="Item limit per page"
                labelInfo="(optional)"
            >
                <InputGroup
                    id="limit"
                    type="number"
                    placeholder={10}
                    min={1}
                    max={100}
                    defaultValue={limit > 0 ? limit : ''}
                    onChange={(event) => setLimit(parseInt(event.currentTarget.value, 10) || 0)}
                />
            </FormGroup>

            <FormGroup
                labelFor="limitParameter"
                label="Name of limit parameter in URL"
                labelInfo="(optional)"
                helperText="This will reduce the amount of network traffic."
            >
                <InputGroup
                    id="limitParameter"
                    placeholder="limit"
                    defaultValue={limitParameter}
                    onChange={(event) => setLimitParameter(event.currentTarget.value)}
                />
            </FormGroup>

            <Checkbox
                label="Infinite scrolling enabled"
                checked={scrolling}
                onChange={(event) => setScrolling(event.currentTarget.checked)}
            />

            {scrollingControls}

            <Button
                intent={Intent.PRIMARY}
                minimal={true}
                disabled={url === ''}
                fill={true}
                text="Set JSON Feed"
                onClick={_update}
            />
        </div>
    )
}

FeedOptions.defaultProps = {
    dataSource: {
        url: '',
    },
}

const Feed = ({ row }) => {
    const [isOpen, setOpen] = useState(false)

    const {
        updateRowDataSource,
        deleteRowDataSource,
    } = useDocumentUpdateTools()

    const _handleClose = () => {
        setOpen(false)
    }

    const _onUpdate = (dataSource) => {
        updateRowDataSource(row, dataSource)
        _handleClose()
    }

    const _onClearFeed = () => {
        deleteRowDataSource(row)
        _handleClose()
    }

    return (
        <React.Fragment>
            <Button
                minimal={true}
                icon="bring-data"
                active={false}
                onClick={() => setOpen(true)}
            />
            <Dialog
                icon="bring-data"
                onClose={_handleClose}
                title="Data Source"
                isOpen={isOpen}
            >
                <div className={Classes.DIALOG_BODY}>
                    {row.dataSource ? (
                        <React.Fragment>
                            <Button
                                intent={Intent.PRIMARY}
                                minimal={true}
                                fill={true}
                                text="Clear Data Source"
                                onClick={_onClearFeed}
                            />
                            <Divider />
                        </React.Fragment>
                    ) : null}

                    <FeedOptions
                        onUpdate={_onUpdate}
                        dataSource={row.dataSource}
                    />
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button minimal={true} onClick={_handleClose}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Dialog>
        </React.Fragment>
    )
}

export default Feed
